/*
 * @Author: chenjx
 * @Date: 2023-02-24 15:11:02
 * @LastEditors: chenjx
 * @LastEditTime: 2023-03-03 15:08:31
 * @Description: file content
 * @FilePath: \best\src\main.js
 */
import Vue from 'vue'
import { Pagination, Message, Loading } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import http from './http'
import store from './store'

Vue.prototype.$http = http
Vue.prototype.$message = Message
Vue.config.productionTip = false
Vue.use(Pagination)
Vue.use(Loading)


const app = new Vue({
  router,
  store,
  render: h => h(App),
})
app.$mount('#app')
