<!--
 * @Author: chenjx
 * @Date: 2023-02-24 15:34:54
 * @LastEditors: chenjx
 * @LastEditTime: 2023-04-04 10:35:11
 * @Description: file content
 * @FilePath: \best\src\components\Footer.vue
-->
<template>
  <div class="footer">
    <div class="container">
      <ul class="footer_top">
        <li class="col-md-3 col-xs-6">
          <p class="p1"><img src="../assets/images/icon5.png" alt=""></p>
          <p class="p2">7x24 小时服务支持</p>
        </li>
        <li class="col-md-3 col-xs-6">
          <p class="p1"><img src="../assets/images/icon6.png" alt=""></p>
          <p class="p2">按需发送灵活计费</p>
        </li>
        <li class="col-md-3 col-xs-6">
          <p class="p1"><img src="../assets/images/icon7.png" alt=""></p>
          <p class="p2">公开透明价格方案</p>
        </li>
        <li class="col-md-3 col-xs-6">
          <p class="p1"><img src="../assets/images/icon8.png" alt=""></p>
          <p class="p2">1v1 客户专享服务</p>
        </li>
      </ul>
      <div class="footer_middle">
        <div class="footer_left">
          <div class="logo"><img :src="info.logo" alt=""></div>
          <div class="text">
            <div class="tel"><img src="../assets/images/footer1.jpg" alt="">{{ info.tel }}</div>
            <!-- <ul class="list">
              <li>
                <a href=""><img src="../assets/images/footer2.jpg" alt=""></a>
              </li>
              <li>
                <a href=""><img src="../assets/images/footer3.jpg" alt=""></a>
              </li>
              <li>
                <a href=""><img src="../assets/images/footer4.jpg" alt=""></a>
              </li>
              <li>
                <a href=""><img src="../assets/images/footer5.jpg" alt=""></a>
              </li>
              <li>
                <a href=""><img src="../assets/images/footer6.jpg" alt=""></a>
              </li>
              <li>
                <a href=""><img src="../assets/images/footer7.jpg" alt=""></a>
              </li>
            </ul> -->
          </div>
        </div>
        <ul class="footer_center">
          <li class="col-xs-4" v-for="item in footers" :key="item.id">
            <RouterLink :to="`${item.channel_type}`">{{ item.title }}</RouterLink>
            <template v-if="item.subs && item.subs.length > 0">
              <RouterLink
                :to="`${child.channel_type}/${encodeURIComponent(child.id)}?id=${encodeURIComponent(item.id)}`"
                v-for="child in item.subs" :key="child.id">
                {{ child.title }}
              </RouterLink>
            </template>
          </li>
        </ul>
        <div class="code" v-if="info.qrcode_img">
          <p class="p1"><img :src="info.qrcode_img" alt=""></p>
          <p class="p2">{{ info.qrcode_text }}</p>
        </div>
      </div>
      <div class="copyright">{{ info.title }} ©️ 2023 ALL RIGHTS RESERVED. 保留所有权利          <span v-html="info.reg_no"></span></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    footers: {
      default: () => [
        {
          id: "1",
          title: "首页",
          channel_type: "/index",
          image: "",
          subs: [],
        },
        {
          id: "5",
          title: "产品服务",
          channel_type: "/product",
          image: "",
          subs: [
            {
              id: "6",
              title: "卡片视频",
              channel_type: "/product/card",
              image: "",
            },
            {
              id: "7",
              title: "视频短信",
              channel_type: "/product/video",
              image: "",
            },
            {
              id: "8",
              title: "短信",
              channel_type: "/product/message",
              image: "",
            },
          ],
        },
        {
          id: "2",
          title: "文档中心",
          channel_type: "/word",
          image: "",
          subs: [],
        },
        {
          id: "3",
          title: "新闻动态",
          channel_type: "/news",
          image: "",
          subs: [],
        },
        {
          id: "4",
          title: "关于我们",
          channel_type: "/about",
          image: "",
          subs: [],
        },
      ],
      required: true,
      type: Array
    },
    info: {
      default: () => {
        return {
          logo: require('../assets/images/logo2.png'),
          qrcode: require('../assets/images/code.jpg')
        }
      },
      required: true,
      type: Object
    }
  },
  data() {
    return {}
  },
}
</script>