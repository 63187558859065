/*
 * @Author: chenjx
 * @Date: 2023-02-25 14:48:10
 * @LastEditors: chenjx
 * @LastEditTime: 2023-03-14 09:36:57
 * @Description: file content
 * @FilePath: \best\src\apis\index.js
 */
const api = {
  baseInfo: '/api/best/index/baseinfo',
  about: '/api/best/index/about',
  sendcode: '/api/best/index/sendcode',
  product: '/api/best/index/product',
  newslist: '/api/best/index/newslist',
  doclist: '/api/best/index/doclist',
  words: '/api/best/index/words',
  newsdetail: '/api/best/index/newsdetail',
  docdetail: '/api/best/index/docdetail',
  index: '/api/best/index/index'
}

const apis = {}
Object.keys(api).forEach(key => {
  apis[key] = `/apis${api[key]}`
})

export default apis