<!--
 * @Author: chenjx
 * @Date: 2023-02-24 15:11:02
 * @LastEditors: chenjx
 * @LastEditTime: 2023-03-04 13:25:21
 * @Description: file content
 * @FilePath: \best\src\App.vue
-->
<template>
  <div id="app">
    <Header :logo="info.logo" :routerData="headers"></Header>
    <!-- <Aside :info="info"></Aside> -->
    <div class="content-container">
      <keep-alive>
        <RouterView></RouterView>
      </keep-alive>
    </div>
    <Footer :footers="footers" :info="info"></Footer>
  </div>
</template>

<script>

import apis from '@/apis'
// import Aside from './components/Aside.vue'
import Footer from './components/Footer.vue'
import Header from './components/Header.vue'

export default {
  name: 'App',
  components: {
    Header,
    // Aside,
    Footer
  },
  data() {
    return {
      info: {},
      headers: [],
      footers: [],
    }
  },
  created() {
    this.getBaseInfo()
  },
  methods: {
    /**
     * @description: 获取网页基础信息
     * @return {*}
     */    
    getBaseInfo() {
      this.$http.post(apis.baseInfo).then(res => {
        if (res.data.code === 0) {
          const { info, headers, footers } = res.data.data
          const menu = {}
          headers.forEach(element => {
              menu[element.channel_type] = element.id
              if (element.channel_type === '/news' || element.channel_type === '/word') {
                element.channel_type = `${element.channel_type}?id=${element.id}`
              }
          });
          this.info = info
          this.headers = headers
          this.footers = footers
          document.title = info.title
          sessionStorage.setItem('headers', JSON.stringify(headers))
          sessionStorage.setItem('menu', JSON.stringify(menu))
        }
      })
    }
  }
}
</script>

<style>
@import './assets/css/reset.css';
@import './assets/css/bootstrap.css';
@import './assets/css/animate.css';
@import './assets/css/swiper.min.css';
@import './assets/css/css.css';
@import './assets/css/mycss.css';
#app::-webkit-scrollbar{
  width: 0;
}
.content-container {
  /* padding-top: 113px; */
  min-height: calc(100vh - 320px);
}
@media (max-width: 767px) {
  .content-container {
    /* padding-top: 65px; */
    min-height: calc(100vh - 323px);
  }
  div .header.header2 {
    height: auto;
  }
}
.router-link-active {
  color: #0046BF !important;
}
</style>
