/*
 * @Author: chenjx
 * @Date: 2023-02-27 17:12:47
 * @LastEditors: chenjx
 * @LastEditTime: 2023-02-27 18:39:30
 * @Description: file content
 * @FilePath: \best\src\store\index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    q: ''
  },
  mutations: {
    setQ(state, payload) {
      state.q = payload
    }
  }
})